import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import StoryblokTemplate from "~templates/storyblok.jsx";
import { breakpoint } from "~utils/css.js";

/** ============================================================================
 * @component
 * Product graphql wrapper.
 * @param  {data}     object  Data inherited from Storyblok plugin.
 * @param  {location} object  Data inherited Gatsby @reach/router.
 * @return {node}     <main>  Wrapped StoryblokTemplate JSX.
 * todo: custom seo
 */
const StoryblokInvestor = ({ data, location, draftBridge }) => {
  // --------------------------------------------------------------------------
  // variables

  const isIndex = data?.storyblokEntry?.full_slug === `investors/`;

  let components = [
    {
      component: `InvestorsNav`
    },
    {
      component: `InvestorBreadcrumbs`,
      background: `grey-10`,
      color: `grey-70`,
      rootLabel: { label: `Company` }
    }
  ];

  if (isIndex) {
    components = [
      {
        component: `InvestorsNav`
      },
      {
        component: `CompanyBanner`,
        animation_id: `investor`,
        heading: `Investors`,
        subheading: `Advancing how the world builds`
      }
    ];
  }

  // --------------------------------------------------------------------------
  // render

  return (
    <StoryblokTemplate
      css={css`
        padding-top: var(--header-height-touch);

        ${breakpoint(`large-tablet`)} {
          padding-top: calc(var(--header-height-desktop));
        }
      `}
      data={data}
      draftBridge={draftBridge}
      location={location}
      components={components}
    />
  );
};

export default StoryblokInvestor;

export const query = graphql`
  query ($id: String!) {
    storyblokEntry(id: { eq: $id }) {
      id
      full_slug
      name

      content
    }
  }
`;
